import FactoryService from "../../components/factory/FactoryService";
import ErrorHandling from "../../components/ErrorHandling";
import i18n from "../../i18n";
import UserService from "../../components/user/UserService";

import { version } from "@/../package.json";

export const SUPERVISOR_ROLE = "supervisor";
export const DIRECTOR_ROLE = "director";
export const PRESENTER_ROLE = "presenter";

export default {
  namespaced: true,

  state: {
    userId: "",
    email: "",
    username: "",
    role: "",
    isAdmin: false,
    isWxUser: false,
    insightsEnabled: false,
    isFinancialAdmin: false,
    isTilelyticsEnabled: false,
    accountId: null,
    factories: [],
    language: "en",
    theme: "dark",
    accessible: false,
    preferences: null,
    availableCurrencies: [],
    workShifts: [],
    applicationVersion: version,
  },

  getters: {
    applicationVersion(state) {
      return state.applicationVersion;
    },
    userId(state) {
      return state.userId;
    },
    email(state) {
      return state.email;
    },
    username(state) {
      return state.username;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isWxUser(state) {
      return state.isWxUser;
    },
    insightsEnabled(state) {
      return state.insightsEnabled;
    },
    isFinancialAdmin(state) {
      return state.isFinancialAdmin;
    },
    isTilelyticsEnabled(state) {
      return state.isTilelyticsEnabled;
    },
    isLoggedIn(state) {
      return state.userId !== "";
    },
    loggedInUserRole(state) {
      return state.role;
    },
    isSupervisor(state) {
      return state.role === "supervisor";
    },
    isPresenter(state) {
      return state.role === "presenter";
    },
    isDirector(state) {
      return state.role === "director";
    },
    accountId(state) {
      return state.accountId;
    },
    factories(state) {
      return state.factories;
    },
    workShifts(state) {
      return state.workShifts;
    },
    language(state) {
      return state.language;
    },
    isSpanish(state) {
      return state.language === "es";
    },
    theme(state) {
      return state.theme;
    },
    isAccessible(state) {
      return state.accessible;
    },
    preferences(state) {
      return state.preferences;
    },
    availableCurrencies(state) {
      return state.availableCurrencies;
    },
  },

  actions: {
    fetchUserFactory: function ({ commit, dispatch }) {
      return FactoryService.getFactoriesForUser()
        .then((response) => {
          if (response.status === 200) {
            const factories = response.data.map((f) => ({
              id: f.id,
              name: f.name,
              timezone: f.timezone,
              primaryLanguage: f.primary_language,
              yearFirstMonth: f.year_first_month ? f.year_first_month : "january",
              weekFirstDay: f.week_first_day ? f.week_first_day : "monday",
              currency: f.currency ? f.currency : "CAD",
              productionDayMinutesFromMidnight: f.production_day_minutes_from_midnight
                ? f.production_day_minutes_from_midnight
                : 0,
              isCalendarDayBusinessDay: f.is_calendar_day_business_day ? f.is_calendar_day_business_day : true,
              productionUnits: f.production_units.map((pu) => ({
                id: pu.id,
                name: pu.name,
                convertedUnitName: pu.converted_unit_name,
                packages: pu.packages,
              })),
              factoryObjectives: f.objectives.map((obj) => ({
                value: obj.value,
                objective: obj.objective_type,
              })),
              alertCapability: f.alert_capability,
            }));
            let activeFactory = factories[0];

            commit("setFactories", factories);
            dispatch("navigation/setActiveFactory", activeFactory, { root: true });
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },
    fetchWorkShifts({ commit }) {
      return UserService.getProductionUnitWorkShifts()
        .then((response) => {
          commit("setWorkShifts", response.data);
        })
        .catch((error) => console.log(error));
    },
    updateFactorySettings({ commit }, idAndUpdatedSettings) {
      const factoryId = idAndUpdatedSettings.factoryId;
      const settings = idAndUpdatedSettings.settings;
      return FactoryService.updateFactorySettings(factoryId, settings)
        .then(() => {
          let localeMessages = i18n.t("factory.wizard.successfullyUpdated", { name: settings.name });
          commit("operation/showOperationSuccess", localeMessages, { root: true });
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              switch (code) {
                case "CS_FAC_PUT_20001":
                  return i18n.t("factory.wizard.errors.duplicateName", { name: this.name });
                default:
                  return i18n.t("common.errors.default", { code: error });
              }
            }),
            { root: true },
          );
        });
    },
    updateLanguage({ commit, getters }, newLanguage) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;
              preferences.language = newLanguage;
              preferencesDB.language = newLanguage;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateTheme({ commit, getters }, newTheme) {
      if (getters.userId) {
        // The module `theme` values are restricted to 'dark' and 'light' only.
        // If the user selected the `Accessible` theme, translate it to `light` with the flag `accessible` set to true.
        // NOTE: it's the module Javascript object `preferences` we set that way, not the `preferencesDB`!!
        const theme = newTheme === "accessible" ? "light" : newTheme;
        const accessible = newTheme === "accessible";

        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              // `preferences` is the (vuex) store object
              let preferences = getters.preferences;
              preferences.theme = theme;
              preferences.accessible = accessible;
              commit("setPreferences", preferences);

              // `preferencesDB` is the payload sent to the backend service and persisted to the database.
              let preferencesDB = response.data;
              preferencesDB.theme = newTheme;
              preferencesDB.accessible = accessible;
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateLastActiveFilter({ commit, getters }, newFilterId) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              preferencesDB.tilelytics.selected_tilelytics_filter_id = newFilterId;
              commit("setPreferences", preferencesDB);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateTimeRangeOption({ commit, getters }, updateTimeRange) {
      if (getters.userId) {
        UserService.getUserPreferences()
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              preferencesDB.tilelytics.selected_time_option = updateTimeRange.value;
              preferencesDB.tilelytics.selected_start_date = updateTimeRange.start_date;
              preferencesDB.tilelytics.selected_end_date = updateTimeRange.end_date;
              commit("setPreferences", preferencesDB);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    fetchAvailableCurrencies: function ({ commit }) {
      return FactoryService.getAvailableCurrencyCodes()
        .then((response) => {
          if (response.status === 200) {
            commit("setAvailableCurrencies", response.data.currencies);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },
  },

  mutations: {
    setUser(state, user) {
      state.userId = user.userId;
      state.email = user.email;
      state.username = user.username;
      state.role = user.role;
      state.isAdmin = user.isAdmin;
      state.isFinancialAdmin = user.isFinancialAdmin;
      state.isTilelyticsEnabled = user.isTilelyticsEnabled;
      state.isWxUser = user.isWxUser;
      state.insightsEnabled = user.insightsEnabled;
    },
    clearUser(state) {
      state.userId = "";
      state.email = "";
      state.username = "";
      state.role = "";
      state.isAdmin = false;
      state.isFinancialAdmin = false;
      state.isTilelyticsEnabled = false;
    },
    setAccountId(state, accountId) {
      state.accountId = accountId;
    },
    setFactories(state, factories) {
      state.factories = factories;
    },
    setWorkShifts(state, workShifts) {
      state.workShifts = workShifts;
    },
    setPreferences(state, preferences) {
      state.preferences = preferences;
      state.language = preferences.language;
      state.theme = preferences.theme;
      state.accessible = preferences.accessible;

      if (preferences.theme === "accessible" || preferences.accessible) {
        state.theme = "light";
        state.accessible = true;
      } else {
        state.theme = preferences.theme;
        state.accessible = false;
      }
    },
    setAvailableCurrencies(state, currencies) {
      state.availableCurrencies = currencies;
    },
  },
};
